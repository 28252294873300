<template>
    <div class="modal-overlay" v-if="isVisible">
      <div class="modal-container">
        <header class="modal-header">
          <h2>🎟️ Tickets pour l'événement</h2>
          <button class="close-btn" @click="closeModal">×</button>
        </header>
  
        <div class="modal-body">
          <!-- Loader Section -->
          <div v-if="isLoading" class="loading-container">
            <i class="fas fa-spinner fa-spin spinner"></i>
            <p>Chargement des tickets...</p>
          </div>
  
          <!-- No Tickets Found -->
          <div v-else-if="tickets.length === 0" class="no-tickets">
            <i class="fas fa-ticket-alt"></i>
            <p>Aucun ticket n'a été encore acheté pour cet événement.</p>
          </div>
  
          <!-- Tickets List -->
          <div v-else class="tickets-list">
            <div class="tickets-summary">
                <h3 class="tickets-header">🎫 Tickets achetés :</h3>
                <p class="tickets-count">
                Total : <strong>{{ totalTicketsQuantity }}</strong> tickets
                </p>
            </div>
            <ul class="tickets-container">
                <li v-for="ticket in tickets" :key="ticket.id" class="ticket-item">
                <div class="ticket-details">
                    <span class="ticket-title">
                    🎟️ <strong>{{ ticket.ticket_name }}</strong> [{{ ticket.purchase_quantity }}]
                    </span>
                    <br/>
                    <span class="ticket-status">
                    <strong>Status : </strong> 
                    <span :class="`status-${ticket.ticket_status.toLowerCase()}`">
                        {{ ticket.ticket_status }}
                    </span>
                    </span>
                </div>
                <div class="ticket-meta">
                    <span>Date : {{ formatShortDateText(ticket.purchase_date) }}</span>
                    <span>Prix : {{ ticket.purchase_total_price }} {{ ticket.purchase_currency }}</span>
                </div>
                </li>
            </ul>
            </div>

        </div>
  
        <footer class="modal-footer">
          <button class="close-modal-btn" @click="closeModal"><i class="fas fa-times"></i> Fermer</button>
        </footer>
      </div>
    </div>
</template>
  
<script>
  import axios from 'axios';
  import { SERVER_IP } from '../../config';

  import { formatShortDateText } from '@/utils/validationUtils';
  
  export default {
    props: {
      userId: {
        type: Number,
        required: true,
      },
      eventId: {
        type: Number,
        required: true,
      },
      isVisible: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        tickets: [],
        isLoading: true,
        currency: "USD", // Exemple, modifiez si nécessaire
      };
    },
    watch: {
      isVisible(newVal) {
        if (newVal) {
          this.loadTickets();
        }
      },
    },

    computed: {
        totalTicketsQuantity() {
            return this.tickets.reduce((sum, ticket) => sum + (ticket.purchase_quantity || 0), 0);
        },
    },
    methods: {
      
        formatShortDateText(date) {
            return formatShortDateText(date, true);
        },

        async loadTickets() {
            this.isLoading = true;
            this.tickets = [];
            const url = `${SERVER_IP}/eventsManagement/loadTicketsUser`;
            try {

                const response = await axios.post(url, {
                    userId: this.userId,
                    eventId: this.eventId
                });
                if (response.status === 200 ) {
                    this.tickets = response.data.tickets
                    console.log(this.tickets);
                }
            } catch (error) {
            console.error("Erreur lors de la récupération des tickets :", error);
            this.tickets = [];
            } finally {
            this.isLoading = false;
            }
        },

        closeModal() {
            if(!this.isLoading)
                this.$emit("close");
        },
        
    },
  };
  </script>
  
  <style scoped>
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-container {
    background: white;
    border-radius: 8px;
    width: 100%;
    max-width: 350px;
    padding: 16px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 16px;
  }
  
  .modal-header h2 {
    font-size: 1.25rem;
    color: #333;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-body {
    min-height: 200px;
    text-align: center;
    padding: 16px;
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    color: #555;
  }
  
  .spinner {
    font-size: 2rem;
    color: #007bff;
  }
  
  .no-tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    color: #888;
  }
  
  .no-tickets i {
    font-size: 2rem;
    color: #ccc;
  }

  .tickets-list {
    text-align: left;
    margin: 20px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 16px;
    }

    .tickets-summary {
    margin-bottom: 16px;
    text-align: left;
    }

    .tickets-header {
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: #007bff;
    }

    .tickets-count {
    font-size: 1rem;
    color: #333;
    }

    .tickets-container {
    list-style-type: none;
    padding: 0;
    margin: 0;
    }

    .ticket-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    border-bottom: 1px solid #eee;
    font-size: 0.95rem;
    }

    .ticket-item:last-child {
    border-bottom: none;
    }

    .ticket-details {
    flex: 1;
    margin-right: 16px;
    }

    .ticket-title {
    color: #000;
    }
    
    .ticket-title strong {
    color: #000;
    font-size: 1rem;
    }

    .ticket-status {
    font-size: 0.9rem;
    margin-top: 4px;
    }
    .ticket-status strong {
        color: #000;
    }

    .ticket-status .status-active {
    color: #28a745;
    }

    .ticket-status .status-pending {
    color: #ffc107;
    }

    .ticket-status .status-cancelled {
    color: #dc3545;
    }

    .ticket-meta {
    text-align: right;
    font-size: 0.85rem;
    color: #555;
    }

    .ticket-meta span {
    display: block;
    margin-bottom: 4px;
    }

  
  
  .modal-footer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  
  .close-modal-btn {
    background-color: #016A40; /* Fond vert */
    color: white; /* Texte blanc */
    border: none;
    border-radius: 5px; /* Coins moins circulaires */
    padding: 10px 20px; /* Ajustement du rembourrage */
    cursor: pointer;
    transition: background-color 0.3s; /* Transition pour l'animation */
    width: auto;
    margin-top: 5%;
  }
  
  .close-modal-btn:hover {
    background: #005934;
  }
  </style>
  