<template>
    <div v-if="visible" class="modal"  @click.self="closeModal">
      <div class="modal-content">
        <div v-if="loading" class="modal-loading">
          <i class="fas fa-spinner fa-spin"></i> Chargement en cours...
        </div>
        
        <div v-else>
          <div v-if="errorMessage" class="modal-error">
            <p>{{ errorMessage }}</p>
          </div>
          
          <div v-if="!canReport">
            <p>Vous ne pouvez pas signaler cet événement, car plus de 24 heures se sont écoulées depuis sa date d'organisation.</p>
            <button class="close-button-success" @click="closeModal"><i class="fas fa-times-circle"></i> Fermer</button>
          </div>
          
          <div v-else>

            <div v-if="successMessage" class="modal-success">
              <p>{{ successMessage }}</p>
              <button class="close-button-success" @click="closeModal"><i class="fas fa-times-circle"></i> Fermer</button>
            </div>
            <div v-else>
              <h2>Signaler un problème concernant l'événement</h2>
              <p>L'événement n'a pas eu lieu comme prévu, ou vous avez rencontré un problème.</p>
  
              <textarea
                v-model="reportDescription"
                class="report-textarea"
                placeholder="Décrivez brièvement le problème rencontré"
                maxlength="500"
              ></textarea>
              
              <div class="form-group file-upload">
                <label for="event-file">Joindre une preuve (image, vidéo ou document pdf - max 5 MB)</label>
                <input type="file" id="event-file" @change="handleFileUpload" accept="image/*,video/*,.pdf" />
                
                <div class="file-preview" @click="triggerFileInput">
                  <template v-if="filePreviewUrl">
                    <template v-if="isImage">
                      <img :src="filePreviewUrl" alt="Prévisualisation de l'image" class="preview-image" />
                    </template>
                    <template v-else-if="isVideo">
                      <video controls class="preview-video">
                        <source :src="filePreviewUrl" type="video/mp4" />
                        Votre navigateur ne supporte pas la lecture de vidéo.
                      </video>
                    </template>
                    <template v-else-if="isDocument">
                      <div class="preview-document">
                        <i class="fas fa-file-alt"></i>
                        <span>{{ fileName }}</span>
                      </div>
                    </template>
                  </template>
                  
                  <template v-else>
                    <i class="fas fa-upload"></i>
                    <span>Aucun fichier sélectionné</span>
                  </template>
                </div>
                
                <div v-if="errorMessage" class="file-error">
                  <p>{{ errorMessage }}</p>
                </div>
              </div>

              <div class="checkbox-container">
                <input type="checkbox" id="confirmationCheckbox" v-model="confirmed" />
                <label for="confirmationCheckbox">Je confirme que les informations fournies sont exactes.</label>
              </div>
              
              <div class="modal-actions">
                <button @click="closeModal" class="cancel-button">Annuler</button>
                
                <button
                  @click="submitReport"
                  class="confirm-button"
                  :disabled="!isFormValid"
                >
                  <template v-if="loadingSender">
                    <i class="fas fa-spinner fa-spin"></i> Action en cours...
                  </template>
                  <template v-else>
                    <i class="fas fa-hand-paper"></i> Signaler le problème
                  </template>
                </button>

              </div>

            </div>

          </div>
          
          
        </div>
      </div>
    </div>
</template>

<script>

  import axios from 'axios';
  import { SERVER_IP } from '../../config';

  export default {
    props: {
      eventId: { type: String, required: true },
      userId: { type: String, required: true },
      eventDate: { type: String, required: true },
      visible: { type: Boolean, required: true },
    },

    data() {
      return {
        loading: true,
        canReport: false,
        reportDescription: '',
        fileData: null,
        confirmed: false,
        successMessage: '',

        file: null,
        fileName: '',
        filePreviewUrl: '',
        errorMessage: '',
        isImage: false,
        isVideo: false,
        isDocument: false,
        loadingSender: false,
      };
    },

    computed: {
      isFormValid() {
        return this.reportDescription.trim() !== '' && this.confirmed;
      },
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.resetModal();
          this.checkEventEligibility();
        }
      },
    },

    methods: {
      resetModal() {
        this.loading = true;
        this.canReport = false;
        this.reportDescription = '';
        this.fileData = null;
        this.confirmed = false;
        this.errorMessage = '';
        this.successMessage = '';

        this.file = null;
        this.fileName = '';
        this.filePreviewUrl = '';
        this.errorMessage = '';
        this.isImage = false;
        this.isVideo = false;
        this.isDocument = false;
        this.loadingSender = false;
      },

      triggerFileInput() {
        document.getElementById('event-file').click();
      },

      resetFileData() {
        this.file = null;
        this.fileName = '';
        this.filePreviewUrl = '';
        this.errorMessage = '';
        this.isImage = false;
        this.isVideo = false;
        this.isDocument = false;
      },


      handleFileUpload(event) {
        const file = event.target.files[0];
        this.resetFileData();

        if (!file) return;

        if (file.size > 5 * 1024 * 1024) {
          this.errorMessage = 'Le fichier ne doit pas dépasser 5 MB.';
          return;
        }

        const fileType = file.type;
        this.file = file;
        this.fileName = file.name;

        if (fileType.startsWith('image/')) {
          this.isImage = true;
          this.createPreviewUrl(file);
        } else if (fileType.startsWith('video/')) {
          this.isVideo = true;
          this.createPreviewUrl(file);
        } else if ( fileType === 'application/pdf' ) {
          this.isDocument = true;
          // this.filePreviewUrl = null; // Pas de prévisualisation pour les documents
          this.createPreviewUrl(file);
        } else {
          this.errorMessage = 'Type de fichier non supporté.';
        }

      },

      createPreviewUrl(file) {
        this.filePreviewUrl = URL.createObjectURL(file);
      },

      async checkEventEligibility() {
        try {
          const eventDate = new Date(this.eventDate);
          const now = new Date();
          const timeDifference = now - eventDate;// - now;
          const hoursDifference = timeDifference / (1000 * 60 * 60);


          console.log(`${hoursDifference}`);

          this.canReport = hoursDifference <= 24;
        } catch (err) {
          this.errorMessage = 'Une erreur est survenue lors de la vérification de l’événement.';
        } finally {
          this.loading = false;
        }
      },
      
      async submitReport() {
        this.loadingSender = true;
        const url = `${SERVER_IP}/eventsManagement/registerComplainte`;

        try {
          const formData = new FormData();
          formData.append('eventId', this.eventId);
          formData.append('userId', this.userId);
          formData.append('description', this.reportDescription);
          if (this.file) {
            formData.append('fileData', this.file);
          }

          // Remplacez par l'appel réel à votre API
          const response = await axios.post(url, formData);
          if (response.status === 200 ) {
            this.successMessage = 'Votre signalement a été enregistré avec succès. Nous prendrons les mesures nécessaires.';
          }else {
            this.errorMessage = 'Une erreur est survenue lors de l’enregistrement de votre signalement.';
          }
        } catch (err) {
          this.errorMessage = 'Une erreur est survenue lors de l’enregistrement de votre signalement.';
        } finally {
          this.loadingSender = false;
        }
      },
      closeModal() {
        if(!this.loading && !this.loadingSender)
          this.$emit('close');
      },
    },
  };
</script>


<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #000;
}

.modal-loading,
.modal-error,
.modal-success {
  margin: 20px 0;
  font-size: 16px;
}

.report-textarea {
  width: 90%;
  height: 100px;
  margin: 15px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.file-upload {
  margin: 10px 0;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cancel-button,
.confirm-button {
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.cancel-button {
  background: #ccc;
  border: none;
}

.confirm-button {
  background: #016a40;
  color: white;
  border: none;
}

.confirm-button:disabled {
  background: #aaa;
  cursor: not-allowed;
}



.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload #event-file {
  display: none;
}

.file-upload label {
  text-align: left;
}

.file-upload .file-preview {
  margin-top: 10px;
  padding: 15px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  color: #777;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload .file-preview i {
  font-size: 2rem;
  margin-bottom: 8px;
}

.preview-image {
  max-width: 350; /* Limite maximale de largeur */
  max-height: 200px; /* Limite maximale de hauteur */
  width: auto; /* Ajuste automatiquement la largeur pour conserver le ratio */
  height: auto; /* Ajuste automatiquement la hauteur pour conserver le ratio */
  border-radius: 15px; /* Coins arrondis */
  object-fit: contain; /* Maintient tout l'image visible dans les limites */
  display: block; /* Aligne correctement l'image */
  margin: 0 auto; /* Centre l'image horizontalement */
}

.preview-video {
  max-width: 350px; /* Limite maximale de largeur */
  max-height: 200px; /* Limite maximale de hauteur */
  width: auto; /* Ajuste automatiquement la largeur pour conserver le ratio */
  height: auto; /* Ajuste automatiquement la hauteur pour conserver le ratio */
  border-radius: 15px; /* Coins arrondis pour un aspect homogène avec les images */
  object-fit: contain; /* Garantit que toute la vidéo est visible dans les limites */
  display: block; /* Aligne correctement la vidéo */
  margin: 0 auto; /* Centre la vidéo horizontalement */
  background-color: #000; /* Ajoute un fond noir pour une meilleure visibilité */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Donne une ombre légère pour un effet élégant */
}

.close-button-success {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 5px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  width: auto;
  margin-top: 5%;
}

.close-button-success:hover {
    background-color: #005934;
  }

</style>