<template>
    <div v-if="visible" class="modal-overlay" @click.self="closeModal">
      <div class="modal-container">
        <div class="modal-header">
          <h3 class="modal-title">Configuration de l'événement</h3>
          <button class="close-icon" @click="closeModal">
            <i class="fas fa-times"></i>
          </button>
        </div>
  
        <!-- Options générales -->
        <div class="modal-section">
          <h3 class="section-title">Options</h3>
          <ul class="option-list">
            <li @click="selectOption('viewTickets')">
              <i class="fas fa-ticket-alt icon-bg"></i> Voir mes tickets
            </li>
            <li @click="selectOption('viewComments')">
              <i class="fas fa-comments icon-bg"></i> Commentaires
            </li>
            <li @click="selectOption('shareEvent')">
              <i class="fas fa-share-alt icon-bg"></i> Partager
            </li>
            <li @click="selectOption('reportProblem')">
              <i class="fas fa-exclamation-triangle icon-bg"></i> Signaler un problème
            </li>
          </ul>
        </div>
  
        <!-- Options organisateur (conditionnelles) -->
        <div class="modal-section" v-if="isOrganizer">
          <h3 class="section-title">Organisateur</h3>
          <ul class="option-list">
            <li @click="selectOption('editEvent')">
              <i class="fas fa-edit icon-bg"></i> Modifier l'événement
            </li>
            <li @click="selectOption('manageTickets')">
              <i class="fas fa-tasks icon-bg"></i> Gestion des tickets
            </li>
            <li @click="selectOption('viewStats')">
              <i class="fas fa-chart-bar icon-bg"></i> Les statistiques
            </li>
            <li @click="selectOption('deleteEvent')">
              <i class="fas fa-trash icon-bg icon-bg-red"></i> <span class="text-red">Supprimer</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: 'EventConfigModal',

    props: {
      visible: {
        type: Boolean,
        required: true,
      },
      isOrganizer: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      closeModal() {
        this.$emit("close"); // Émet un événement pour fermer le modal
      },
      selectOption(option) {
        this.$emit("selectOption", option); // Émet un événement avec l'option choisie
      },
    },
  };
</script>
  
<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-container {
    background: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 330px;
    width: 90%;
    color: #000;
    text-align: center;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-title {
    font-size: 1.25rem;
    margin: 0;
  }
  
  .close-icon {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #555;
    cursor: pointer;
  }
  
  .close-icon:hover {
    color: #000;
  }
  
  .modal-section {
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .option-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .option-list li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .option-list li:hover {
    background: #f0f0f0;
  }
  

  .icon-bg {
    background-color: #016A40;
    color: white;
    padding: 5px; /* Taille des icônes réduite */
    padding-top: 10px;
    border-radius: 8px; /* Coins arrondis */
    margin-right: 10px;
    font-size: 0.75rem; /* Réduire la taille des icônes */
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 0.9em;
  }
  .icon-bg-red {
    background-color: #960909;
  }
  .text-red {
    color: #960909;
  }
  </style>
  