<template>
    <div v-if="visible" class="modal-overlay"  @click.self="closeModal">
      <div class="modal-container">
        <!-- Titre du Modal -->
        <h2 class="modal-title">Evaluer cet événement</h2>
        
        <!-- Afficher le spinner pendant le chargement -->
        <div v-if="loading" class="loading-spinner">
          <i class="fas fa-spinner fa-spin"></i> Chargement...
        </div>

        <div v-else>
          <!-- Section Like/Dislike -->
          <div class="feedback-section">
            <p class="section-title">Votre avis :</p>
            <div class="like-dislike-buttons">
              <button 
                  :class="{ active: feedback.likeDislike === 'like' }" 
                  @click="setLikeDislike('like')"><i class="fas fa-heart"></i>
              </button>
              <button 
                  :class="{ active: feedback.likeDislike === 'dislike' }" 
                  @click="setLikeDislike('dislike')"><i class="fas fa-heart-crack"></i>
              </button>
              </div>
          </div>
    
          <!-- Section Notation par étoiles -->
          <div class="feedback-section">
            <p class="section-title">Notez l'événement :</p>
            <div class="stars">
              <span 
                v-for="star in 5" 
                :key="star" 
                :class="{ filled: star <= feedback.rating }" 
                @click="setRating(star)">★</span>
            </div>
          </div>

    
          <!-- Section Commentaire -->
          <div class="feedback-section">
            <p class="section-title">Ajoutez un commentaire :</p>
            <textarea v-model="feedback.comment" @input="handleCommentInput" placeholder="Exprimez votre avis ici..."></textarea>
          </div>
          
          <div class="error-message">
            <span v-if="savingError">{{ savingError }}</span>
          </div>
          
    
          <!-- Bouton Enregistrer -->
          <div class="modal-actions">            
            <button class="cancel" @click="closeModal"><i class="fas fa-times"></i> Annuler</button>

            <button @click.stop="saveFeedback" :disabled="loadingSave">
              <span v-if="loadingSave">
                Validation en cours <i class="fas fa-spinner fa-spin"></i>
              </span>
              <span v-else>
                <i class="fas fa-check-circle"></i> Valider
              </span>
            </button>

          </div>

        </div>

      </div>
    </div>
</template>

<script>
import axios from 'axios';
import { SERVER_IP } from '../../config';

export default {
  name: 'ModalEventFeedback',

  props: {
    visible: Boolean,
    eventId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      feedback: {
        likeDislike: 'neutral', // 'like' ou 'dislike'
        rating: 0,        // 0 à 5
        comment: "",      // Texte du commentaire
      },
      loadingSave: false,
      savingError: '',
      loading: false,
    };
  },

  mounted() {
    if (this.visible) {
      this.loadFeedback();
    }
  },

  methods: {
    setLikeDislike(option) {
        if (this.feedback.likeDislike === option) {
        // Si l'utilisateur clique sur l'option déjà sélectionnée, on désélectionne tout
        this.feedback.likeDislike = 'neutral';
        } else {
        // Sinon, on met à jour avec l'option choisie
        this.feedback.likeDislike = option;
        }
        this.savingError = "";
    },
    setRating(star) {
      // Si l'utilisateur clique sur l'étoile déjà sélectionnée, réinitialiser la note
      if (this.feedback.rating === star) {
        this.feedback.rating = 0;
      } else {
        this.feedback.rating = star;
      }
      this.savingError = "";
    },
    handleCommentInput(){
      this.savingError = "";
    },

    async saveFeedback() {
      if(this.loadingSave) {
        return;
      }

      this.loadingSave = true; // Démarrage du chargement

      const url = `${SERVER_IP}/eventsManagement/setFeedback`;
      const payload = {
        eventId: this.eventId,
        userId: this.userId,
        ...this.feedback,
      };

      try {
        // Envoi de la requête au backend
        const response = await axios.post(url, payload);

        // Vérification de la réponse du serveur
        if (response.status === 200) {
          this.loadingSave = false;
          this.closeModal(); // Fermer le modal si tout est OK
        } else {
          console.warn(`Réponse inattendue : ${response.status}`, response.data);
          this.savingError = "Une erreur est survenue lors de l'enregistrement de votre feedback. Veuillez réessayer.";
        }
      } catch (error) {
        this.savingError = "Une erreur réseau ou serveur est survenue. Veuillez vérifier votre connexion ou réessayer plus tard.";
      } finally {
        // Assurez-vous que le chargement est désactivé
        this.loadingSave = false;
      }
    },

    async loadFeedback() {
    
      this.loading = true; // Démarrage du chargement
      const url = `${SERVER_IP}/eventsManagement/getFeedback`;
      try {
        const response = await axios.post(url, {
            userId: this.userId,
            eventId: this.eventId
        });

        if (response.status === 200 ) {
            // On met à jour les données locales après réception de l'API
            this.feedback = response.data.feedback;   // Tableau des économies
        }
        
      } catch (error) {
          console.log(error.response.data.message);
      } finally {
        this.loading = false;
      }
    },

    closeModal() {
      if (!this.loadingSave && !this.loading)
        this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-container {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #000;
}

.feedback-section {
  margin-bottom: 15px;
}

.section-title {
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.like-dislike-buttons button {
  padding: 10px 15px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
}

.like-dislike-buttons button.active {
  background: #016A40;
  color: #fff;
}

.stars span {
  font-size: 1.5rem;
  cursor: pointer;
  color: #ddd;
  margin: 0% 1.5%;
}

.stars span.filled {
  color: #f39c12;
}

textarea {
  width: 90%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.modal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-actions button.cancel {
  background: #f5f5f5;
  color: #333;
}

.modal-actions button:not(.cancel) {
  background: #016A40;
  color: #fff;
}

.error-message {
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #770a06; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  margin-top: 5px;
  margin-top: -1%;
  margin-bottom: 2%;
}

.loading-spinner {
  color: #000;
  min-height: 200px;
  padding: 10%;
  font-size: 1.2rem;
}
</style>

  