<template>
    <div v-if="visible" class="modal-overlay" @click.self="closeModal">
      <div class="modal-container">
        <div class="modal-header">
          <h2 class="modal-title">Connexion requise</h2>
          <button class="close-icon" @click="closeModal">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <p class="modal-message">Vous devez être connecté pour accéder à cette fonctionnalité.</p>
        <button class="login-button" @click="redirectToLogin">
          <i class="fas fa-user-circle"></i> Se connecter
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      redirectToLogin() {
        window.location.href = '/'; // Redirection vers la page de connexion
      },
      closeModal() {
        this.$emit('close'); // Émet un événement pour fermer le modal
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-container {
    background: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    max-width: 350px;
    width: 350px;
    color: #000;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .close-icon {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #555;
    cursor: pointer;
  }
  
  .close-icon:hover {
    color: #000;
  }
  
  .modal-message {
    font-size: 1rem;
    margin: 20px 0;
  }
  
  .login-button {
    background: #016a40;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .login-button:hover {
    background: #005934;
  }
  </style>
  