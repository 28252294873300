<template>
    <div v-if="visible" class="modal-overlay" @click.self="closeIfAllowed">
      <div class="modal-container">
        <h2 v-if="!deletionSuccessful">Confirmation de suppression de l'événement</h2>
        <h2 v-else>Événement supprimé avec succès</h2>

        <p v-if="!deletionSuccessful">{{ message() }}</p>
        <p v-else>Votre événement a été supprimé. Vous pouvez fermer cette fenêtre.</p>

        
        <!-- Champ de confirmation si suppression possible -->
        <div v-if="!deletionSuccessfull && isDeletionAllowed">
          <label>
            Entrez le nom de l'événement pour confirmer :
            <input
              v-model="eventNameInput"
              :disabled="loading"
              placeholder="Nom de l'événement"
            />
          </label>
          <div>
            <small v-if="error">{{ error }}</small>
          </div>
        </div>
  
        <!-- Boutons -->
        <div class="modal-actions">
          <button class="btn-cancel" @click="closeModal"><i class="fas fa-times"></i> Annuler</button>
          <button
            v-if="!deletionSuccessfull && isDeletionAllowed"
            @click="confirmDeletion"
            :disabled="!isNameMatching"
            :class="{ 'btn-submit': isNameMatching }"
            >
            <span v-if="loading">Suppression en cours... <i class="fas fa-spinner fa-spin"></i></span>
            <span v-else><i class="fas fa-check-circle"></i> Confirmer</span>
            </button>

        </div>
      </div>
    </div>
</template>
  
<script>
  import axios from 'axios';
  import { SERVER_IP } from '../../config';
  import { checkAuth } from '@/utils/validationUtils';

  export default {
    name: 'DeleteEventModal',
    props: {
      visible: Boolean,
      event: Object, // L'objet événement passé au modal
    },
    data() {
      return {
        eventNameInput: "",
        error: "",
        loading: false,
        deletionStatus: false, // Suivi de la suppression
        deletionSuccessfull: false, // Nouveau champ
      };
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          // Réinitialise le champ lorsque le modal devient visible
          this.eventNameInput = "";
          this.deletionStatus = false;
          this.error = "";
          this.loading = false;
          this.deletionSuccessfull = false;
        }
      },
    },
    
    computed: {
      isDeletionAllowed() {

        const now = new Date();
        const eventDate = new Date(this.event.start_date); // Assurez-vous que `start_date` est bien le champ correct

        // Calcul de la différence en minutes
        const diffMinutes = (now - eventDate) / (1000 * 60);

        if (diffMinutes > 1440) {
          // Plus de 24 heures avant l'événement
          return true;
        } else if (diffMinutes > 0) {
          // Entre maintenant et 24 heures avant l'événement
          return false;
        } else {
          // Événement passé
          return true;
        }
      },

      isNameMatching() {
        return this.eventNameInput === this.event.title; // Vérifie si le nom de l'événement est correct
      },

      
    },


    methods: {
      closeIfAllowed() {
        if (!this.loading) this.$emit("close", this.deletionStatus);
      },
      closeModal() {
        if (!this.loading) this.$emit("close", this.deletionStatus);
      },

      message() {
        const now = new Date();
        const eventDate = new Date(this.event.start_date);
        const diffMinutes = (now - eventDate) / (1000 * 60);

        if (diffMinutes > 1440) {
          // Plus de 24 heures avant l'événement
          return `Cet événement est terminé.\n
                  Si vous supprimez cet événement, toutes les données associées seront définitivement effacées.
                  \nÊtes-vous sûr de vouloir continuer ?`;
        } else if (diffMinutes >= 0 && diffMinutes <= 1440) {
          // Entre maintenant et 24 heures avant l'événement
          const hours = Math.floor(diffMinutes / 60);
          const minutes = Math.floor(diffMinutes % 60);
          return `Cet événement a été organisé il y a moins de 24 heures. 
                  Nous ne permettons pas la suppression immédiate pour permettre d’éventuelles réclamations des participants. 
                  \nVeuillez patienter encore ${hours} heures et ${minutes} minutes avant de pouvoir le supprimer.`;
        } else {
          // Événement passé
          return `Cet événement n’a pas encore eu lieu. 
                  La suppression entraînera également le remboursement automatique des tickets déjà achetés.
                  \nÊtes-vous sûr de vouloir continuer ?`;
        }
      },

      async confirmDeletion() {
        if (!this.isNameMatching) {
          this.error = "Le nom ne correspond pas.";
          return;
        }
        this.error = "";
        this.loading = true;
        const userId= checkAuth();
        const url = `${SERVER_IP}/eventsManagement/deleteEvent`;
    
        try {
          const response = await axios.post(url, {
              userId: userId,
              eventId: this.event.id
          });

          if (response.status === 200) {
            this.loading = false;
            this.deletionStatus = true;
            this.deletionSuccessfull = true; // Suppression réussie
          }
        } catch (err) {
          console.error("Erreur lors de la suppression :", err);
          this.error = "La suppression a échoué. Veuillez réessayer.";
          this.deletionStatus = false;
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
  
<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-container {
    background: #fff;
    color: #000;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal h2 {
    margin: 0 0 10px;
  }
  
  .modal-actions {
    margin-top: 20px;
    display: flex; gap: 10px; justify-content: flex-end;
  }
  
  /* Style de base */
button {
  border: none;
}

/* Style pour les boutons activés */
button.btn-submit {
  background-color: #016a40;
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;
  opacity: 1;
}

button.btn-submit:hover {
  background-color: #005934;
  opacity: 1;
}
.btn-cancel {
  background-color: #ccc;
  color: #000;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;
}

/* Bouton désactivé */
button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  
  background-color: #016a40;
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 15px;
}

  
input {
  width: 90%; padding: 8px; margin-top: 8px; border: 1px solid #ccc; border-radius: 4px;
}

small {
  color: red; font-size: 12px;
}
</style>
  