<template>
    <div v-if="visible" class="modal-overlay">
      <div class="modal-content">
        <header class="modal-header">
          <h3>Commentaires de l'événement</h3>
          <button class="close-icon" @click="closeModal">
            <i class="fas fa-times"></i>
          </button>
        </header>
  
        <div v-if="loading" class="spinner">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
  
        <div v-else>
          <div class="comments-container">
            <div v-if="comments.length === 0" class="no-comments">
              Aucun commentaire jusqu'à présent.
            </div>
            <div v-else>
              <div v-for="comment in comments" :key="comment.id" class="comment-box">
                <div class="comment-header">
                  <img
                    v-if="!comment.anonymous"
                    :src="mediaUrls[comment.id] || 'placeholder.jpg'"
                    alt="Profile Thumbnail"
                    class="profile-thumbnail"
                  />
                  <div class="comment-meta">
                    <span class="author-name">{{ comment.anonymous ? 'Anonyme' : comment.author_name }}</span>
                    <span class="created-at">{{ formatShortDateText(comment.created_at) }}</span>
                  </div>
                </div>
                <div class="comment-body">
                  <div v-if="editingCommentId === comment.id" class="edit-comment">
                    <div class="textarea-container">
                      <textarea
                        v-model="comment.comment"
                        class="edit-textarea"
                        maxlength="200"
                        @input="updateRemainingChars(comment)"
                      ></textarea>
                      <span class="char-counter">{{ 120 - comment.comment.length }} caractères restants</span>
                    </div>

                    <div class="edit-actions">
                      
                      <button @click="cancelEdit(comment)" class="cancel-button">
                        <i class="fas fa-times"></i> Annuler
                      </button>
        
                      <button 
                        @click="saveComment(comment)" 
                        class="save-button" 
                        :class="{ 'save-button-disabled': !comment.comment.trim() }"
                        :disabled="!comment.comment.trim()"
                      >
                        <span v-if="comment.editLoading">
                          <i class="fas fa-spinner fa-spin"></i> Enregistrement...
                        </span>
                        <span v-else>
                          <i class="fas fa-save"></i> Enregistrer
                        </span>
                      </button>

                    </div>
                  </div>
                  <div v-else>
                    <p class="comment-text">{{ comment.comment }}</p>
                    <div v-if="comment.user_id === userId" class="comment-actions">
                      <button @click="editComment(comment.id, comment.comment)" class="edit-button">
                        <i class="fas fa-edit"></i> Modifier
                      </button>
                      
                      <button 
                        @click="deleteComment(comment)" 
                        class="delete-button" 
                        :disabled="comment.deleteLoading"
                      >
                        <span v-if="comment.deleteLoading">
                          <i class="fas fa-spinner fa-spin"></i> Suppression...
                        </span>
                        <span v-else>
                          <i class="fas fa-trash"></i> Supprimer
                        </span>
                      </button>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

  
          <button
            v-if="hasMoreComments"
            @click="loadMoreComments"
            class="load-more-button"
          >
            <i class="fas fa-angle-down"></i> Charger plus
          </button>
        </div>
  
        <footer class="modal-footer">
          <textarea
            v-model="newComment"
            class="new-comment-textarea"
            placeholder="Ajoutez un commentaire..."
          ></textarea>
          <button 
            @click="addComment" 
            :disabled="!isCommented"
            :class="[{ 'add-comment-button': isCommented, 'disabled-button': !isCommented }]"
        >
            <span v-if="loadingAdd">
                Publication ... <i class="fas fa-spinner fa-spin"></i>
            </span>
            <span v-else>
                <i class="fas fa-paper-plane"></i> Publier
            </span>
        </button>

        </footer>
      </div>
    </div>
</template>
  
<script>
    import axios from 'axios';
    import { SERVER_IP } from '../../config';
    import { MEDIA_SERVER_URL } from '../../config';

    import { formatShortDateText } from '@/utils/validationUtils';

  export default {
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
      userId: {
        type: Number,
        required: true,
      },
      eventId: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        loading: true,
        comments: [],
        newComment: '',
        editingCommentId: null,
        editContent: '',
        hasMoreComments: false,
        loadedCount: 0,
        loadingAdd: false,
        mediaUrls: {}, // Dictionnaire pour stocker les URLs des images
        allowToCLose: true,
      };
    },
    watch: {
      visible(newVal) {
        if (newVal) {
          // Réinitialise la liste des commentaires et recharge les données
          this.comments = [];
          this.loadedCount = 0; // Réinitialise le compteur de commentaires chargés
          this.loadingAdd = false;
          this.editingCommentId = null;
          this.editContent = '';
          this.allowToCLose = true;
          this.fetchComments();
        }
      },

      comments: {
        handler() {
          this.loadImages(); // Recharge les URLs dès que les événements sont disponibles
        },
        deep: true, // Si `events` est un tableau complexe, ce flag est nécessaire
      },
    },

    computed: {

        isCommented() {
            return this.newComment.trim() !== ""; // Vérifie si le nom de l'événement est correct
        },

    },

    methods: {

      async loadImages() {
        // Charge les URLs des images pour tous les événements
        const urls = {};
        for (const comment of this.comments) {
          urls[comment.id] = await this.getURLImage(comment.profile);
        }
        this.mediaUrls = urls;
      },

      async getURLImage(url) {
        if (!url) {
          return 'placeholder.jpg'; // Si l'URL est vide
        }

        const fullUrl = `${MEDIA_SERVER_URL}users/${url}`;
        try {
          const response = await fetch(fullUrl, { method: 'HEAD' });
          if (response.ok) {
            return fullUrl; // Retourne le lien complet si le fichier existe
          }
          console.warn(`Fichier non trouvé: ${fullUrl}`);
        } catch (error) {
          console.error('Erreur lors de la vérification du fichier :', error);
        }

        // Retourne l'image par défaut si l'URL n'existe pas ou est inaccessible
        return 'placeholder.jpg';
      },

      formatShortDateText(date) {
        return formatShortDateText(date, true);
      },


      async fetchComments() {
        this.loading = true;
        const url = `${SERVER_IP}/eventsManagement/loadComments`;
        try {
            const response = await axios.post(url, {
                userId: this.userId,
                eventId: this.eventId,
                offset: this.loadedCount,
            });

            if (response.status === 200) {
                if (this.loadedCount === 0) {
                    this.comments = response.data.comments;
                } else {
                    this.comments.push(...response.data.comments);
                }
                this.hasMoreComments = response.data.hasMore;
                this.loadedCount += response.data.comments.length;

                console.log(this.comments[0]);
            }
          
        } catch (error) {
          console.error('Erreur lors du chargement des commentaires:', error);
        } finally {
          this.loading = false;
        }
      },


      loadMoreComments() {
        this.fetchComments();
      },


      async addComment() {
        if (!this.newComment.trim()) return;
        this.loadingAdd = true;

        const url = `${SERVER_IP}/eventsManagement/addComment`;
        try {
          const response = await axios.post(url, {
            userId: this.userId,
            eventId: this.eventId,
            comment: this.newComment
          });


          if (response.status === 200) {
            this.loadingAdd = false;
            this.newComment = "";
          }
        } catch (err) {
            console.error("Erreur lors de ajout commentaire:", err);
        } finally {
          this.loadingAdd = false;
        }

      },


      editComment(id, comment) {
        this.editingCommentId = id;
        this.editContent = comment;
      },

      updateRemainingChars(comment) {
        const maxChars = 120;
        comment.remainingChars = maxChars - comment.comment.length;
      },

      async saveComment(comment) {
        if(this.userId !== comment.user_id || comment.comment.trim() === '') {
          return;
        }
        comment.editLoading = true;
        this.allowToCLose = false;
        const url = `${SERVER_IP}/eventsManagement/saveEditComment`;

        try {
          const response = await axios.post(url, {
            userId: this.userId,
            commentID: comment.id,
            comment: comment.comment
          });

          if (response.status === 200) {
            // On retire le commentaire du tableau `comments`
            this.editContent = '';
            this.editingCommentId = null;
          }
        } catch (err) {
          console.error("Erreur lors de la suppression du commentaire :", err);
        } finally {
          comment.editLoading = false;
          this.allowToCLose = true;
        }

        
      },
      cancelEdit(comment) {
        comment.comment = this.editContent;
        this.editContent = '';
        this.editingCommentId = null;
      },
      
      async deleteComment(comment) {
        if (this.userId !== comment.user_id) {
          return;
        }

        comment.deleteLoading = true;
        this.allowToCLose = false;
        const url = `${SERVER_IP}/eventsManagement/deleteComment`;

        try {
          const response = await axios.post(url, {
            userId: this.userId,
            commentID: comment.id
          });

          if (response.status === 200) {
            // On retire le commentaire du tableau `comments`
            this.comments = this.comments.filter(c => c.id !== comment.id);
            this.fetchComments();
          }
        } catch (err) {
          console.error("Erreur lors de la suppression du commentaire :", err);
        } finally {
          // Si le commentaire est toujours dans `this.comments`, réinitialiser `deleteLoading`
          const stillInComments = this.comments.some(c => c.id === comment.id);
          if (stillInComments) {
            comment.deleteLoading = false;
          }
          this.allowToCLose = true;
        }
      },

      closeModal() {
        if(this.allowToCLose)
          this.$emit('close');
      },
      formatDate(date) {
        return new Date(date).toLocaleDateString();
      },
    },
  };
</script>
  
<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-content {
    background: #fff;
    width: 380px;
    max-width: 90%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    color: #000;
    font-size: 1.2rem;
    padding: 0 1rem;
  }

  .close-icon {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #555;
    cursor: pointer;
  }
  
  .close-icon:hover {
    color: #000;
  }


  .modal-footer {
    padding: 1rem;
    display: flex;
    gap: 10px;
    border-top: 1px solid #eee;
  }
  
  .spinner {
    color: #000;
    font-size: 1rem;
    min-height: 50px;
  }

  .comments-container {
  max-height: 300px;
  overflow-y: scroll;
  padding: 1rem;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.comments-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.no-comments {
  text-align: center;
  font-size: 1rem;
  color: #666;
  padding: 20px 0;
}

.comment-box {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.profile-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-meta {
  display: flex;
  flex-direction: column;
}

.author-name {
  font-weight: bold;
  color: #000;
}

.created-at {
  font-size: 0.75rem;
  color: #888;
  font-style: italic;
  text-align: left;
}

.comment-body {
  margin-top: 10px;
}

.comment-text {
  font-weight: bold;
  margin-bottom: 25px;
  color: #000;
  margin-top: 0;
}

.comment-actions {
  display: flex;
  gap: 10px;
}

.edit-button,
.delete-button {
  border: none;
  padding: 10px 15px;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.edit-button {
  background-color: #016A40;
  color: #fff;
}

.edit-button:hover {
  background-color: #014f32;
}

.delete-button {
  background-color: #d9534f;
  color: #fff;
}

.delete-button:hover {
  background-color: #c9302c;
}

.textarea-container {
  position: relative;
  display: flex;
}

.edit-textarea {
  width: 100%; /* Prend toute la largeur du parent */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 0.9rem;
  resize: vertical; /* Autorise uniquement le redimensionnement vertical */
}

.char-counter {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 0.8rem;
  color: #666;
  pointer-events: none; /* Évite que ce texte interfère avec les clics */
}


.edit-actions {
  display: flex; /* Activer Flexbox */
  justify-content: space-between; /* Espacement égal entre les éléments */
  align-items: center; /* Centrer verticalement les éléments */
  gap: 10px; /* Optionnel : ajouter un espacement entre les boutons */
  margin-top: 10px; /* Espacement au-dessus du conteneur */
}

.save-button {
  flex: 1;
  background-color: #016a40;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.save-button:hover {
  background-color: #005934;
}

.save-button-disabled {
  background-color: #cccccc; /* Une couleur gris clair */
  color: #666666; /* Texte en gris */
  cursor: not-allowed; /* Curseur indiquant que l'action est bloquée */
}
.save-button-disabled:hover {
  background-color: #cccccc; /* Une couleur gris clair */
  color: #666666; /* Texte en gris */
  cursor: not-allowed; /* Curseur indiquant que l'action est bloquée */
}

.cancel-button {
  flex: 1;
  background-color: #ddd;
  color: #000;
  border: none;
  padding: 10px 15px;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.cancel-button:hover {
  background-color: #ccc;
}



  .new-comment-textarea {
    flex: 1;
    resize: none;
  }

  .add-comment-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #016A40;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-comment-button:hover {
  background-color: #014f32;
}

.disabled-button {
  background-color: #ccc; /* Couleur grise pour un bouton désactivé */
  color: #666; /* Texte plus clair */
  cursor: not-allowed; /* Changer le curseur pour indiquer l'état désactivé */
  border: none;
}

.disabled-button:hover {
  background-color: #ccc; /* Pas de changement de couleur au survol */
}

</style>
  