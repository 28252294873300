// utils/translator.js
import { createI18n } from 'vue-i18n';

// Définir les messages de traduction
const messages = {
    locale: 'fr',
    fallbackLocale: 'fr',

    en: { 
        pageLogin: {
            pageTitle: 'Access Your Account',
            phoneOrEmail: 'Phone number or email address',
            password: 'Password',
            stayConnected: 'Keep me signed in',
            forgotPassword: 'Forgot password?',
            connecting: 'Please wait, signing in..',
            connect: 'Log in',
            createAccount: 'Sign up',
            noAccount: 'Don’t have an account?',
            noAccountFound: 'No account found for this phone number or email.',
            invalidPassword: 'Incorrect password.',
            accountInactive: 'Your account is not yet activated. Please check your email to activate it.',
            enterPhone: 'Please enter your phone number or email.',
            enterValidPhone: 'Please enter a valid email or phone number.',
            enterPassword: 'Please enter your password.',
        },
        pageRecoveryPassword: {
            pageTitle: 'Forgot Password',
            phoneOrEmail: 'Phone number or email address',
            loading: 'Loading...',
            reset: 'Reset',
            accueil: 'Home page',
            linkSent: 'A reset link has been sent. Please check your email.',
            errorRegistered: 'An error occurred during the reset request.',
        },

        message: { 
            hello: 'Hello World',
            stayConnected: 'Stay connected',
            fullName: 'Full Name',
            email: 'Email',
            phoneNumber: 'Phone Number',
            address: 'Address',
            dateOfBirth: 'Date of Birth',
            gender: 'Gender',
            personalInfo: 'Personal Information',
            securityPrivacy: 'Security and Privacy',
            preferences: 'Preferences',
            activityHistory: 'Activity History',
            accountManagement: 'Account Management',
            supportAssistance: 'Support and Assistance',
        },
        pageTitle: { 
            profile: 'My Profile',
        },

        generalContent:{
            validateContactError_1: 'The Phone Number or Email field is required.',
            validateContactError_2: 'Please enter a valid email or phone number.',
            passwordPattern: 'The password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a special symbol.',
        },

        pageBasicTransfert: {
            pageTitle: 'Initiating a Transfer',
            phoneOrEmail2: 'Beneficiary’s phone or email',
            phoneemail3: 'Enter the phone number or email',
            emptyfnb: 'The Phone Number or Email field is required.',
            wrongnuberemail: 'Please enter a valid email or phone number.',
            devise: 'Currency Selection',
            Mount: 'Amount',
            AmountInput: 'Enter the amount',
            withoutmount: 'Please check your amount',
            Boutton: 'Submit',
            subboutton: 'Processing...',
            wrongaccount: 'No associated accounts found.',
            Insufficient_funds: 'Your balance is insufficient for the amount plus the transfer fee.',
            no_pin: 'For security reasons, you must first create an authentication key before you can perform transactions.<br/><br/> This key will serve as a second factor of authentication to validate your financial operations.<br/><br/> Please configure your authentication key in your profile to proceed.',
            create_pin: 'Set up',
            name: 'Beneficiary',
            deviseT: 'Currency',
            fees: 'Transaction Fees',
            mountT: 'Total Amount',
            pin: 'Enter your PIN',
            Boutton2: 'Confirm',
            subboutton2: 'Transfer in progress',
            errorpin: 'Your PIN is incorrect.',
            pageTitle3: 'Transfer Complete',
            success: 'Your transaction has been successfully completed.',
            Boutton3: 'Home',
            infoMissed: 'Please fill in all the required fields.',
            inactifAccount: 'The account is inactive.',
            sameAccount: 'You cannot transfer to the same account.',
            unknownError: 'Unknown error. Please try again.',
            pinALpha: 'Please fill in all the fields only with numbers.'
        },

        pageBasicWithdraw:{
            title: 'Withdraw Funds',
        },
        pageBasicDeposit:{
            pageTitle: 'Deposit Money to Your Account',
            account: "Agent's Phone or Email",
            devise: "Select a Currency",
            amount: "Amount",
            underAmount: "Enter the amount",
            underAccount: "Enter the agent's email or phone number",
            nameHTG: "Gourd",
            nameUSD: "Dollar",
            button: "Submit",
            notPutAmount: "Please verify your amount",
            tooMuchAmount: "This agent cannot process this deposit for you",
            unknownNumberOrMail: "No associated accounts found",
            inactifAccount: 'The account is inactive.',
            unknownError: 'Unknown error. Please try again.',
            sameAccount: 'You cannot transfer to the same account.',
            infoMissed: 'Please fill in all the required fields.',

            noRecordPin: 'For security reasons, you must first create an authentication key before you can perform transactions.<br/><br/> This key will serve as a second factor of authentication to validate your financial operations.<br/><br/> Please set up your authentication key in your profile to continue.',
            configure: 'Set Up',

            name: "Agent",
            deviseT: "Currency",
            fees: "Transaction Fees",
            mountT: "Total Amount",
            enterPin: "Enter Your PIN",
            button2: "Confirm",
            subBoutton2: "Processing transaction...",
            badPin: 'Please fill in all fields with numbers.',
            errorPin: "Your PIN is incorrect",

            successDeposit: 'Your deposit has been successfully completed.',
            Button3: 'Home',
            waitingText: 'Your deposit request is being processed.\n\nThe provider must still validate the transaction.\n\nYou can track the progress or cancel the request at any time by viewing your <a href="/transaction-history">transaction history</a> or your <a href="/notifications">notifications</a>.',

        },
        pageConfirmWithdraw:{
            title: 'Authorize Withdrawal',
        },
        pageConfirmDeposit:{
            title: 'Deposit Confirmation',
        },
        pageTracking:{
            title: 'Transaction Tracking',
        },

        pageNotifications: { 
            title: 'Notifications',
        },
        CreatePin: { 
            title: 'Create your code PIN',
        },
        
    },

    fr: { 
        pageLogin: {
            pageTitle: 'Se connecter',
            phoneOrEmail: 'Numéro de téléphone ou adresse email',
            password: 'Mot de passe',
            stayConnected: 'Restez connecté',
            forgotPassword: 'Mot de passe oublié ?',
            connecting: 'Connexion en cours...',
            connect: 'Se connecter',
            createAccount: 'Kreye yon kont',
            noAccount: 'Vous n\'avez pas de compte ?',
            noAccountFound: 'Aucun compte associé à ce nom numéro de téléphone ou email.',
            invalidPassword: 'Le mot de passe est incorrect.',
            accountInactive: 'Votre compte n\'est pas encore activé. Veuillez vérifier votre email pour l\'activer.',
            enterPhone: 'Veuillez entrer votre No de téléphone ou email.',
            enterValidPhone: 'Veuillez entrer un email valide ou un numéro de téléphone valide.',
            enterPassword: 'Veuillez entrer votre mot de passe.',
        },
        pageRecoveryPassword: {
            pageTitle: 'Mot de passe oublié',
            phoneOrEmail: 'Numéro de téléphone ou adresse email',
            loading: 'En cours de chargement...',
            reset: 'Réinitialiser',
            accueil: 'Accueil',
            linkSent: 'Un lien de réinitialisation a été envoyé. Veuillez consulter votre email.',
            errorRegistered: 'Une erreur est survenue pendant la demande de réinitialisation.',
        },

        message: { 
            hello: 'Bonjour le monde',
            stayConnected: 'Rester connecté',
            fullName: 'Nom Complet',
            email: 'Email',
            phoneNumber: 'No de Téléphone',
            address: 'Adresse',
            dateOfBirth: 'Date de Naissance',
            gender: 'Genre',
            personalInfo: 'Informations Personnelles',
            securityPrivacy: 'Sécurité et Confidentialité',
            preferences: 'Préférences',
            activityHistory: 'Historique des Activités',
            accountManagement: 'Gestion de Compte',
            supportAssistance: 'Support et Assistance',
        },
        pageTitle: {
            profile: 'Mon Profil',
        },

        generalContent:{
            validateContactError_1: 'Le champ Numéro de téléphone ou Email est requis.',
            validateContactError_2: 'Veuillez entrer un email valide ou un numéro de téléphone valide.',
            passwordPattern: 'Le mot de passe doit comporter au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un symbole spécial.',
        },

        pageBasicTransfert: {
            pageTitle: 'Effectuer un Transfert',
            phoneOrEmail2: 'Téléphone ou email du bénéficiaire',
            phoneemail3: 'Entrez l’email ou le numéro de téléphone',
            emptyfnb: 'Le champ Numéro de téléphone ou Email est obligatoire.',
            wrongnuberemail: 'Veuillez entrer un email ou numéro de téléphone valide.',
            devise: 'Choix de la devise',
            Mount: 'Montant',
            AmountInput: 'Entrez le montant',
            withoutmount: 'Veuillez vérifier le montant',
            Boutton: 'Soumettre',
            subboutton: 'Traitement en cours...',
            wrongaccount: 'Aucun compte associé trouvé.',
            Insufficient_funds: 'Votre solde est insuffisant pour le montant et les frais de transfert.',
            no_pin: 'Pour des raisons de sécurité, vous devez d\'abord créer une clé d\'authentification avant de pouvoir effectuer des transactions.<br/><br/>Cette clé servira de second facteur d\'authentification pour valider vos opérations financières.<br/><br/>Veuillez configurer votre clé d\'authentification dans votre profil pour continuer.',
            create_pin: 'Configurer',
            name: 'Bénéficiaire',
            deviseT: 'Devise',
            fees: 'Frais de Transaction',
            mountT: 'Montant Total',
            pin: 'Entrez votre PIN',
            Boutton2: 'Valider',
            subboutton2: 'Transfert en cours...',
            errorpin: 'PIN incorrect.',
            pageTitle3: 'Transfert Réussi',
            success: 'Votre transaction a été effectuée avec succès.',
            Boutton3: 'Accueil',
            infoMissed: 'Veuillez remplir tous les champs obligatoires.',
            inactifAccount: 'Le compte est inactif.',
            sameAccount: 'Vous ne pouvez pas transférer vers le même compte.',
            unknownError: 'Erreur inconnue. Veuillez réessayer.',
            pinALpha: 'Veuillez remplir tous les champs uniquement avec des chiffres.'
        },

        pageBasicWithdraw:{
            title: 'Retirer des Fonds',
        },
        pageConfirmWithdraw:{
            title: 'Autorisation de Retrait',
        },
        pageBasicDeposit:{
            pageTitle: 'Déposer de l\'Argent sur Votre Compte',
            account: "Téléphone ou Email de l'Agent",
            devise: "Choisir une Devise",
            amount: "Montant",
            underAmount: "Entrez le montant",
            underAccount: "Entrez l'email ou le numéro de téléphone de l'agent",
            nameHTG: "Gourde",
            nameUSD: "Dollar",
            button: "Soumettre",
            notPutAmount: "Veuillez vérifier le montant",
            tooMuchAmount: "Cet agent ne peut pas traiter ce dépôt pour vous",
            unknownNumberOrMail: "Aucun compte associé trouvé",
            inactifAccount: 'Le compte est inactif.',
            unknownError: 'Erreur inconnue. Veuillez réessayer.',
            sameAccount: 'Vous ne pouvez pas transférer vers le même compte.',
            infoMissed: 'Veuillez remplir tous les champs obligatoires.',

            noRecordPin: 'Pour des raisons de sécurité, vous devez d\'abord créer une clé d\'authentification avant de pouvoir effectuer des transactions.<br/><br/>Cette clé servira de second facteur d\'authentification pour valider vos opérations financières.<br/><br/>Veuillez configurer votre clé d\'authentification dans votre profil pour continuer.',
            configure: 'Configurer',

            name: "Agent",
            deviseT: "Devise",
            fees: "Frais de Transaction",
            mountT: "Montant Total",
            enterPin: "Entrez Votre PIN",
            button2: "Valider",
            subBoutton2: "transaction en cours...",
            badPin: 'Veuillez remplir tous les champs avec des chiffres.',
            errorPin: "Votre PIN est incorrect",

            successDeposit: 'Votre dépôt  a été effectué avec succès.',
            Button3: 'Accueil',
            waitingText: 'Votre demande de dépôt est en cours de traitement.\nLe fournisseur doit encore valider la transaction.\n\nVous pouvez suivre l’évolution ou annuler la demande à tout moment en consultant votre <a href="/transaction-history">historique de transactions</a> ou vos <a href="/notifications">notifications</a>.',

        },
        pageConfirmDeposit:{
            title: 'Validation de Dépôt',
        },
        pageTracking:{
            title: 'Suivi de Transaction',
        },
        pageNotifications: { 
            title: 'Notifications',
        },
        CreatePin: { 
            title: 'Créer votre code PIN',
        },
    },

    ht: {
        pageLogin: {
            pageTitle: 'Antre nan kont ou',
            phoneOrEmail: 'Nimewo telefòn oswa adrès imèl',
            password: 'Modpas',
            stayConnected: 'Rete konekte',
            forgotPassword: 'Ou bliye modpas ou?',
            connecting: 'Tanpri tann, ap konekte...',
            connect: 'Konekte w',
            createAccount: 'Kreye yon kont',
            noAccount: 'Ou poko gen kont?',
            noAccountFound: 'Pa gen kont ki koresponn ak non itilizatè oswa imèl sa a.',
            invalidPassword: 'Modpas sa a pa kòrèk.',
            accountInactive: 'Kont ou poko aktive. Tanpri tcheke imèl ou pou aktive l.',
            enterPhone: 'Tanpri antre nimewo telefòn ou oswa imèl ou.',
            enterValidPhone: 'Tanpri antre yon imèl oswa yon nimewo telefòn ki valab.',
            enterPassword: 'Tanpri antre modpas ou.',
        },
        pageRecoveryPassword: {
            pageTitle: 'Bliye modpas',
            phoneOrEmail: 'Nimewo telefòn oswa adrès imèl',
            loading: 'Chajman ap fèt...',
            reset: 'Reyajiste',
            accueil: 'Paj dakèy',
            linkSent: 'Yon lyen pou reyajiste modpas al jwenn ou. Tanpri verifye imèl ou.',
            errorRegistered: 'Yon erè rive pandan demann reyajisteman an.',
        },

        message: { 
            fullName: 'Non Konplè',
            email: 'Imèl',
            phoneNumber: 'Nimewo Telefòn',
            address: 'Adrès',
            dateOfBirth: 'Dat Nesans',
            gender: 'Sèks',
            personalInfo: 'Enfòmasyon Pèsonèl',
            securityPrivacy: 'Sekirite ak Konfidansyalite',
            preferences: 'Preferans',
            activityHistory: 'Istwa Aktivite',
            accountManagement: 'Jesyon Kont',
            supportAssistance: 'Sipò ak Asistans',
        },
        pageTitle: {
            profile: 'Pwofil Mwen',
        },
        generalContent:{
            validateContactError_1: 'Nimewo telefòn oswa imèl la obligatwa.',
            validateContactError_2: 'Tanpri antre yon imèl ki valab oswa yon nimewo telefòn ki valab.',
            passwordPattern: 'Modpas la dwe gen omwen 8 karaktè, yon lèt majiskil, yon lèt miniskil, yon chif ak yon senbòl espesyal.',
        },
        pageBasicTransfert: {
            pageTitle: 'Fè yon Transfè',
            phoneOrEmail2: 'Nimewo telefòn oswa imel benefisyè a',
            phoneemail3: 'Antre imel oswa nimewo telefòn',
            emptyfnb: 'Nimewo telefòn oswa imel obligatwa.',
            wrongnuberemail: 'Tanpri antre yon imel oswa nimewo telefòn ki valab.',
            devise: 'Chwazi lajan',
            Mount: 'Kantite lajan',
            AmountInput: 'Antre kantite kòb la',
            withoutmount: 'Tanpri tcheke kantite lajan ou',
            Boutton: 'Voye',
            subboutton: 'Pwosesis ap kontinye...',
            wrongaccount: 'Pa gen okenn kont asosye jwenn.',
            Insufficient_funds: 'Balans ou pa ase pou kantite lajan an ak frè transfè a.',
            no_pin: 'Pou rezon sekirite, ou dwe kreye yon kle otantifikasyon anvan ou kapab fè tranzaksyon.<br/><br/> Kle sa a ap sèvi kòm yon dezyèm faktè otantifikasyon pou valide operasyon finansye ou yo.<br/><br/> Tanpri konfiguré kle otantifikasyon ou nan pwofil ou pou kontinye.',
            create_pin: 'Konfigire',
            name: 'Benefisyè',
            deviseT: 'Lajan',
            fees: 'Frè Tranzaksyon',
            mountT: 'Montan Total',
            pin: 'Antre PIN ou',
            Boutton2: 'Konfime',
            subboutton2: 'Transfè ap fèt...',
            errorpin: 'PIN ou a pa kòrèk."',
            pageTitle3: 'Transfè Reyisi',
            success: 'Tranzaksyon ou reyisi.',
            Boutton3: 'Akèy',
            infoMissed: 'Tanpri ranpli tout chan obligatwa yo.',
            inactifAccount: 'Kont lan inaktif.',
            sameAccount: 'Ou pa ka transfere nan menm kont lan.',
            unknownError: 'Gen on erè nou pa idantifye. Tanpri eseye ankò.',
            pinALpha: 'Tanpri ranpli tout chan yo sèlman ak chif.'
        },

        pageBasicWithdraw:{
            title: 'Retire Lajan',
        },
        pageConfirmWithdraw:{
            title: 'Otorize Retrè',
        },
        pageBasicDeposit:{
            pageTitle: 'Mete Lajan sou Kont Ou',
            account: "Telefòn oswa Imèl Ajan",
            devise: "Chwazi ki Lajan",
            amount: "Kantite Lajan",
            underAmount: "Antre kantite lajan an",
            underAccount: "Antre imèl oswa nimewo telefòn ajan an",
            nameHTG: "Goud",
            nameUSD: "Dola",
            button: "Soumèt",
            notPutAmount: "Tanpri verifye kantite lajan an",
            tooMuchAmount: "Ajan sa a pa ka fè depo sa a pou ou",
            unknownNumberOrMail: "Pa jwenn okenn kont ki asosye",
            inactifAccount: 'Kont lan inaktif.',
            unknownError: 'Gen on erè nou pa idantifye. Tanpri eseye ankò.',
            sameAccount: 'Ou pa ka transfere nan menm kont lan.',
            infoMissed: 'Tanpri ranpli tout chan obligatwa yo.',

            noRecordPin: 'Pou rezon sekirite, ou dwe premye kreye yon kle otantifikasyon anvan ou ka fè tranzaksyon.<br/><br/> Kle sa a ap sèvi kòm yon dezyèm faktè otantifikasyon pou valide operasyon finansye ou yo.<br/><br/> Tanpri configuré kle otantifikasyon ou nan pwofil ou pou kontinye.',
            configure: 'Konfigire',

            name: "Ajan",
            deviseT: "Lajan",
            fees: "Frè Tranzaksyon",
            mountT: "Montan Total",
            enterPin: "Antre PIN ou",
            button2: "Konfime",
            subBoutton2: "Tranzaksyon an ap fèt...",
            badPin: 'Tanpri ranpli tout chan yo ak chif.',
            errorPin: "PIN ou pa kòrèk",

            successDeposit: 'Depo ou an te fèt avèk siksè.',
            Button3: 'Akèy',
            waitingText: 'Demann depo w la ap trete.\n\nFounisè a dwe toujou valide tranzaksyon an.\n\nOu ka swiv pwogrè a oswa anile demann lan nenpòt ki lè lè w konsilte <a href="/transaction-history">istwa tranzaksyon</a> ou oswa <a href="/notifications">notifikasyon</a> ou.',

        },
        pageConfirmDeposit:{
            title: 'Konfime Depo',
        },
        pageNotifications: { 
            title: 'Notifikasyon yo',
        },
        pageTracking:{
            title: 'Swivi Tranzaksyon',
        },
        CreatePin: { 
            title: 'Kreye Kòd PIN ou',
        },
    }
};


// Créer une instance i18n
const i18n = createI18n({
    locale: 'fr', // Langue par défaut
    messages,
});

export default i18n;
